<template>
    <div>
        <b-alert show variant="warning" class="text-center p-1">
            {{ this.type === 'edit' ? 
                'Po spremembi storitve, je potrebnih nekaj sekund, da so spremembe vidne. Če spremembe storitve na seznamu niso vidne, poskusite osvežiti spletno stran.' :
                'Po oddaji storitve, je potrebnih nekaj sekund, da je storitev prikazana na seznamu. Če storitve na seznamu ne vidite, poskusite osvežiti spletno stran.'
            }}
        </b-alert>
        <b-form-group>
            <b-form-checkbox switch v-model="object.active">Aktivno</b-form-checkbox>
        </b-form-group>
        <b-form-group label="Ponudnik">
            <validation-provider #default="{ errors }" name="ponudnik" rules="required">
                <v-select v-model="object.service_provider" label="name" :options="serviceProviders" :reduce="option => option.id" :clearable="false"/>
                <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
        </b-form-group>
        <b-form-group label="Naslov">
            <validation-provider #default="{ errors }" name="naslov" rules="required|min:5|max:120">
                <b-form-input v-model="object.title" placeholder="Naslov"/>
                <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
        </b-form-group>
        <b-form-group label="Opis">
            <validation-provider #default="{ errors }" name="opis" rules="required|min:5|max:15000">
                <TextAreaEditor v-model="object.content" />
                <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
        </b-form-group>
        <h2 class="my-1">Koriščenje</h2>
        <b-form-group label="Tip koriščenja">
            <validation-provider #default="{ errors }" name="tip koriščenja" rules="required">
                <div class="d-flex">
                    <b-form-radio v-model="object.usage_type" name="usage_type" :value="0">Uporaba obrazca</b-form-radio>
                    <b-form-radio class="ml-2" v-model="object.usage_type" name="usage_type" :value="1">Uporaba kupona</b-form-radio>
                </div>
                <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
        </b-form-group>
        <b-form-group label="Navodila za koriščenje">
            <validation-provider #default="{ errors }" name="navodila za koriščenje" rules="max:15000">
                <TextAreaEditor v-model="object.instructions" />
                <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
        </b-form-group>

        <b-row align-h="center">
            <b-col class="text-center">
                <ImageSelector ref="imageSelector" :main_photo="object.main_photo" :additional_photos="object.additional_photos" @update-main-photo="updateMainPhoto" :type="type"/>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import { ValidationProvider } from 'vee-validate'
    import { required, maxValue, minValue, max, email } from '@validations'
    import TextAreaEditor from '../Components/TextAreaEditor'
    import ImageSelector from '../Components/ImageSelector'
    import vSelect from 'vue-select'
    import {BAlert, BCol, BFormCheckbox, BFormGroup, BFormInput, BFormRadio, BRow} from 'bootstrap-vue'
    export default {
        components: {
            ValidationProvider,
            TextAreaEditor,
            ImageSelector,
            vSelect,
            BRow,
            BCol,
            BFormGroup,
            BFormInput,
            BFormRadio,
            BFormCheckbox,
            BAlert
        },
        model: {
            prop: 'model',
            event: 'input'
        },
        props: {
            model: {
                type: Object,
                default: null
            },
            value: {
                default: null
            },
            type: {
                type: String
            },
            serviceProviders: {
                type: Array
            }
        },
        data() {
            return {
                required,
                max,
                maxValue,
                minValue,
                email
            }
        },
        methods: {
            updateMainPhoto(photo) {
                this.object.main_photo = photo
            }
        },
        computed: {
            object: {
                get() {
                    return this.model
                },
                set(value) {
                    this.$emit('input', value)
                }
            }
        }
    }
</script>

<style scoped>

</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>