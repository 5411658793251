var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-alert',{staticClass:"text-center p-1",attrs:{"show":"","variant":"warning"}},[_vm._v(" "+_vm._s(this.type === 'edit' ? 'Po spremembi storitve, je potrebnih nekaj sekund, da so spremembe vidne. Če spremembe storitve na seznamu niso vidne, poskusite osvežiti spletno stran.' : 'Po oddaji storitve, je potrebnih nekaj sekund, da je storitev prikazana na seznamu. Če storitve na seznamu ne vidite, poskusite osvežiti spletno stran.')+" ")]),_c('b-form-group',[_c('b-form-checkbox',{attrs:{"switch":""},model:{value:(_vm.object.active),callback:function ($$v) {_vm.$set(_vm.object, "active", $$v)},expression:"object.active"}},[_vm._v("Aktivno")])],1),_c('b-form-group',{attrs:{"label":"Ponudnik"}},[_c('validation-provider',{attrs:{"name":"ponudnik","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","options":_vm.serviceProviders,"reduce":function (option) { return option.id; },"clearable":false},model:{value:(_vm.object.service_provider),callback:function ($$v) {_vm.$set(_vm.object, "service_provider", $$v)},expression:"object.service_provider"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Naslov"}},[_c('validation-provider',{attrs:{"name":"naslov","rules":"required|min:5|max:120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Naslov"},model:{value:(_vm.object.title),callback:function ($$v) {_vm.$set(_vm.object, "title", $$v)},expression:"object.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Opis"}},[_c('validation-provider',{attrs:{"name":"opis","rules":"required|min:5|max:15000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextAreaEditor',{model:{value:(_vm.object.content),callback:function ($$v) {_vm.$set(_vm.object, "content", $$v)},expression:"object.content"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('h2',{staticClass:"my-1"},[_vm._v("Koriščenje")]),_c('b-form-group',{attrs:{"label":"Tip koriščenja"}},[_c('validation-provider',{attrs:{"name":"tip koriščenja","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex"},[_c('b-form-radio',{attrs:{"name":"usage_type","value":0},model:{value:(_vm.object.usage_type),callback:function ($$v) {_vm.$set(_vm.object, "usage_type", $$v)},expression:"object.usage_type"}},[_vm._v("Uporaba obrazca")]),_c('b-form-radio',{staticClass:"ml-2",attrs:{"name":"usage_type","value":1},model:{value:(_vm.object.usage_type),callback:function ($$v) {_vm.$set(_vm.object, "usage_type", $$v)},expression:"object.usage_type"}},[_vm._v("Uporaba kupona")])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Navodila za koriščenje"}},[_c('validation-provider',{attrs:{"name":"navodila za koriščenje","rules":"max:15000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextAreaEditor',{model:{value:(_vm.object.instructions),callback:function ($$v) {_vm.$set(_vm.object, "instructions", $$v)},expression:"object.instructions"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-row',{attrs:{"align-h":"center"}},[_c('b-col',{staticClass:"text-center"},[_c('ImageSelector',{ref:"imageSelector",attrs:{"main_photo":_vm.object.main_photo,"additional_photos":_vm.object.additional_photos,"type":_vm.type},on:{"update-main-photo":_vm.updateMainPhoto}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }